import axios, { AxiosResponse } from "axios";

interface CacheItem {
  value: any;
  expiry: number;
}
export const httpClient = axios.create({
  baseURL: process.env.API_REACT_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
});

export function fetchWithCache(url: string) {
  const cachedData = localCache.get(url);
  if (cachedData) {
    return Promise.resolve({ data: cachedData });
  } else {
    return httpClient.get(url).then((response) => {
      localCache.set(url, response.data);
      return response;
    });
  }
}

// 로컬 캐시 저장소 객체
const localCache = {
  set: function (key: string, value: any, expireTime: number = 15 * 60 * 1000) {
    // 기본 캐시 시간: 15분
    const item: CacheItem = { value, expiry: Date.now() + expireTime };
    localStorage.setItem(key, JSON.stringify(item));
  },
  get: function (key: string): any {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item: CacheItem = JSON.parse(itemStr);
    if (Date.now() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
};

// 요청 인터셉터 추가
httpClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      config.headers = config.headers ?? {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 응답 인터셉터
httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // 응답 데이터에 따라 처리 로직, 예를 들어 특정 코드에 대해 처리하는 등의 작업
    if (response.data.code && response.data.code !== "100") {
      // 에러 처리 로직
      const error = new Error(
        `Server returned an error with code: ${response.data.code} / ${response.data.message}`
      );
      return Promise.reject(error);
    } else if (response.data.statusCode && response.data.statusCode !== "100") {
      // 에러 처리 로직
      const error = new Error(
        `Server returned an error with code: ${response.data.statusCode} / ${response.data.message}`
      );
      return Promise.reject(error);
    }

    return response;
  },
  (error) => {
    // 응답 에러 처리 로직
    return Promise.reject(error);
  }
);
