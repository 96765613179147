import { Mobile, Default, Tablet, Desktop } from "../Device";
import {
  BodyView,
  PathStringView,
  BannerView,
  LearningImageView,
  ContactUSMapView,
  ContactUsTitleView,
  ContactUsThanksTextView,
  ContactUsInfoAddressView,
  ContactUsInfoText1View,
  ContactUsInfoIconView,
  ContactUsInfoText3View,
  ContactUsSectionView,
  ContactUsInfoView,
  ContactUsInfoPhoneView,
} from "./common";

function ContactUs() {
  return (
    <>
      <Mobile>
        <MobileContactus />
      </Mobile>
      <Desktop>
        <DesktopContactus />
      </Desktop>
      <Tablet>
        <TabletContactus />
      </Tablet>
    </>
  );
}

function MobileContactus() {
  return (
    <>
      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/admission/mobile/banner.png"
          alt="contactus_img01"
          width={"100%"}
        />
      </LearningImageView>
      <BodyView>
        <ContactUsSectionView style={{ marginTop: "3.7209vw" }}>
          <ContactUsTitleView className="text_wrap">
            CHARLIE'S BIG RED HOUSE{"\n"}Hannam 1 (3~4 age)
          </ContactUsTitleView>
          <ContactUsInfoView>
            <Address data={"서울시 용산구\n독서당로35길 13"} />
            <Phone data="02-517-0345" />
          </ContactUsInfoView>
          <Map data="https://playsongshome.playsongs.ai/charlie/contactus/mobile/map1.png" />
        </ContactUsSectionView>
        <ContactUsSectionView>
          <ContactUsTitleView className="text_wrap">
            CHARLIE’S BIG RED HOUSE{"\n"}Hannam 2 (5~7 age)
          </ContactUsTitleView>
          <ContactUsInfoView>
            <Address data={"서울시 용산구\n독서당로 124-13"} />
            <Phone data="02-6279-0345" />
          </ContactUsInfoView>
          <Map data="https://playsongshome.playsongs.ai/charlie/contactus/tablet/map2.png" />
        </ContactUsSectionView>

        <ContactUsSectionView>
          <ContactUsTitleView>
            CHARLIE’S BIG RED HOUSE{"\n"}Gwanggyo (5~10 age)
          </ContactUsTitleView>
          <ContactUsInfoView>
            <Address
              data={"경기도 수원시 영통구\n광교호수공원로80 203~207호"}
            />
            <Phone data="031-8019-8228" />
          </ContactUsInfoView>
          <Map data="https://playsongshome.playsongs.ai/charlie/contactus/mobile/map3.png" />
        </ContactUsSectionView>
      </BodyView>
    </>
  );
}

function TabletContactus() {
  return (
    <BodyView>
      <LearningImageView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/admission/tablet/banner.png"
          alt="banner admission"
          width={"100%"}
        />
      </LearningImageView>
      <ContactUsThanksTextView>
        Thanks for your intest in CHARLIE'S BIG RED HOUSE.
      </ContactUsThanksTextView>
      <ContactUsSectionView style={{ marginTop: "2.6042vw" }}>
        <ContactUsTitleView className="text_wrap">
          CHARLIE'S BIG RED HOUSE Hannam 1 {"\n"}(3~4 age)
        </ContactUsTitleView>
        <ContactUsInfoView>
          <Address data={"서울시 용산구 독서당로35길 13"} />
          <Phone data="02-517-0345" />
        </ContactUsInfoView>
        <Map data="https://playsongshome.playsongs.ai/charlie/contactus/tablet/map1.png" />
      </ContactUsSectionView>

      <ContactUsSectionView>
        <ContactUsTitleView className="text_wrap">
          CHARLIE'S BIG RED HOUSE Hannam 2{"\n"}(5~7 age)
        </ContactUsTitleView>
        <ContactUsInfoView>
          <Address data={"서울시 용산구 독서당로 124-13"} />
          <Phone data="02-6279-0345" />
        </ContactUsInfoView>
        <Map data="https://playsongshome.playsongs.ai/charlie/contactus/tablet/map2.png" />
      </ContactUsSectionView>

      <ContactUsSectionView>
        <ContactUsTitleView className="text_wrap">
          CHARLIE'S BIG RED HOUSE gwanggyo{"\n"}(5~10 age)
        </ContactUsTitleView>
        <ContactUsInfoView>
          <Address data={"경기도 수원시 영통구\n광교호수공원로80 203~207호"} />
          <Phone data="031-8019-8228" />
        </ContactUsInfoView>
        <Map data="https://playsongshome.playsongs.ai/charlie/contactus/tablet/map3.png" />
      </ContactUsSectionView>
    </BodyView>
  );
}
function DesktopContactus() {
  return (
    <BodyView>
      <PathStringView className="path">Contact us</PathStringView>
      <div className="separatorline" />
      <BannerView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/admission/banner.png"
          alt="contactus-img01"
          width={"100%"}
        />
      </BannerView>
      <ContactUsThanksTextView>
        Thanks for your intest in CHARLIE'S BIG RED HOUSE.
      </ContactUsThanksTextView>
      <ContactUsSectionView style={{ marginTop: "3.7209vw" }}>
        <ContactUsTitleView>
          CHARLIE'S BIG RED HOUSE Hannam 1 (3~4 age)
        </ContactUsTitleView>
        <ContactUsInfoView>
          <Address data={"서울시 용산구 독서당로35길 13"} />
          <Phone data="02-517-0345" />
        </ContactUsInfoView>
        <Map data="https://playsongshome.playsongs.ai/charlie/contactus/pc/map1.png" />
      </ContactUsSectionView>

      <ContactUsSectionView>
        <ContactUsTitleView>
          CHARLIE'S BIG RED HOUSE Hannam 2 (5~7 age)
        </ContactUsTitleView>
        <ContactUsInfoView>
          <Address data={"서울시 용산구 독서당로 124-13"} />
          <Phone data="02-6279-0345" />
        </ContactUsInfoView>
        <Map data="https://playsongshome.playsongs.ai/charlie/contactus/pc/map2.png" />
      </ContactUsSectionView>

      <ContactUsSectionView>
        <ContactUsTitleView>
          CHARLIE'S BIG RED HOUSE gwanggyo (5~10 age)
        </ContactUsTitleView>
        <ContactUsInfoView>
          <Address data={"경기도 수원시 영통구\n광교호수공원로80 203~207호"} />
          <Phone data="031-8019-8228" />
        </ContactUsInfoView>
        <Map data="https://playsongshome.playsongs.ai/charlie/contactus/pc/map3.png" />
      </ContactUsSectionView>
    </BodyView>
  );
}

interface TextProps {
  data: string;
}

function Address({ data }: TextProps) {
  return (
    <ContactUsInfoAddressView>
      <ContactUsInfoIconView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/icon/icon-address.png"
          alt="icon"
          height={"100%"}
        />
      </ContactUsInfoIconView>
      <ContactUsInfoText1View>Address</ContactUsInfoText1View>
      <ContactUsInfoText3View className="text_wrap">
        {data}
      </ContactUsInfoText3View>
    </ContactUsInfoAddressView>
  );
}

function Phone({ data }: TextProps) {
  return (
    <ContactUsInfoPhoneView>
      <ContactUsInfoIconView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/icon/icon-phone.png"
          alt="icon"
          height={"100%"}
        />
      </ContactUsInfoIconView>
      <ContactUsInfoText1View>Phone</ContactUsInfoText1View>
      <ContactUsInfoText3View className="text_wrap">
        {data}
      </ContactUsInfoText3View>
    </ContactUsInfoPhoneView>
  );
}
function Map({ data }: TextProps) {
  return (
    <ContactUSMapView>
      <img src={data} alt="map3" width={"100%"} />
    </ContactUSMapView>
  );
}
export default ContactUs;

//
