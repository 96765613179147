import {
  renderTextWithSpans,
  StringManager,
} from "../../../assets/StringManager";
import { ScreenType, useScreenType } from "../../../Componets/Devicets";
import {
  BodyView,
  LearningDescriptionKView,
  LearningDescriptionView,
  LearningFontTextView,
  LearningImageView,
  LearningTextView,
  LearningTitleView,
} from "../../common";

export default function Language() {
  const screenType = useScreenType();
  return (
    <>
      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/pc/1017/learning.jpg"
          alt="preschool_img01.jpg"
          width={"100%"}
        />
      </LearningImageView>
      {screenType === "mobile" ? (
        <>
          <BodyView>{LanguageTextView(screenType)}</BodyView>
        </>
      ) : (
        LanguageTextView(screenType)
      )}
    </>
  );
}

function LanguageTextView(screenType: ScreenType) {
  return (
    <>
      <LearningFontTextView>
        {renderTextWithSpans("languagePartner")}
      </LearningFontTextView>
      <LearningTextView>
        <LearningTitleView>
          {StringManager.learningLanguageString.language.title}
        </LearningTitleView>
        <LearningDescriptionView className="text_wrap">
          {renderTextWithSpans("language")}
        </LearningDescriptionView>
        <LearningDescriptionKView className="text_wrap">
          {StringManager.learningLanguageString.language.k}
        </LearningDescriptionKView>
      </LearningTextView>
    </>
  );
}
