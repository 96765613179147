import { SetStateAction, useState } from "react";
import { Desktop, Mobile, Tablet } from "../../Device";
import { BodyView, PathStringView } from "../common";
import AfterSchool from "./Learning/Afterschool";
import ArtAMusic from "./Learning/Art";
import Language from "./Learning/Language";
import Numbers from "./Learning/Numbers";
import TabMenu from "./Tab/TabMenu";

interface ITab {
  currTab: number;
}
function Learning() {
  const [currentTab, clickTab] = useState(0);

  const menuArr = [
    { name: "Language Arts\n/Literacy" },
    { name: "Numbers and Sensory" },
    { name: "Arts and Music" },
    { name: "After School Class" },
  ];

  const selectMenuHandler = (index: SetStateAction<number>) => {
    clickTab(index);
  };

  return (
    <>
      <BodyView>
        <Desktop>
          <PathStringView className="path">Learning</PathStringView>
          <div className="separatorline" />
        </Desktop>
        <TabMenu
          currentTab={currentTab}
          menuArr={menuArr}
          onClickTab={selectMenuHandler}
        />
      </BodyView>

      <Desktop>
        <DesctopView currTab={currentTab} />
      </Desktop>
      <Tablet>
        <TabletView currTab={currentTab} />
      </Tablet>
      <Mobile>
        <MobileView currTab={currentTab} />
      </Mobile>
    </>
  );
}

function DesctopView({ currTab }: ITab) {
  return (
    <BodyView>
      {currTab === 0 && <Language />}
      {currTab === 1 && <Numbers />}
      {currTab === 2 && <ArtAMusic />}
      {currTab === 3 && <AfterSchool />}
    </BodyView>
  );
}

function TabletView({ currTab }: ITab) {
  return (
    <BodyView>
      {currTab === 0 && <Language />}
      {currTab === 1 && <Numbers />}
      {currTab === 2 && <ArtAMusic />}
      {currTab === 3 && <AfterSchool />}
    </BodyView>
  );
}

function MobileView({ currTab }: ITab) {
  return (
    <>
      {currTab === 0 && <Language />}
      {currTab === 1 && <Numbers />}
      {currTab === 2 && <ArtAMusic />}
      {currTab === 3 && <AfterSchool />}
    </>
  );
}
export default Learning;
