import {
  renderTextWithSpans,
  StringManager,
} from "../../../assets/StringManager";
import { ScreenType, useScreenType } from "../../../Componets/Devicets";
import {
  BodyView,
  LearningDescriptionKView,
  LearningDescriptionView,
  LearningFontTextView,
  LearningImageView,
  LearningTextView,
  LearningTitleView,
} from "../../common";

export default function NumbersASensory() {
  const screenType = useScreenType();
  return (
    <>
      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/learning2.png"
          alt="preschool_img01.jpg"
          width={"100%"}
        />
      </LearningImageView>
      {screenType === "mobile" ? (
        <>
          <BodyView>{TextView(screenType)}</BodyView>
        </>
      ) : (
        TextView(screenType)
      )}
    </>
  );
}
function TextView(screenType: ScreenType) {
  return (
    <>
      <LearningTextView>
        <LearningTitleView>
          {StringManager.learningLanguageString.number.title}
        </LearningTitleView>
        <LearningDescriptionView>
          {renderTextWithSpans("number")}
        </LearningDescriptionView>
        <LearningDescriptionKView className="text_wrap">
          {StringManager.learningLanguageString.number.k}
        </LearningDescriptionKView>
      </LearningTextView>
    </>
  );
}
