import { useMediaQuery } from "react-responsive";

export type ScreenType = "pc" | "tablet" | "mobile";

export const useScreenType = (): ScreenType => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (isDesktop) return "pc";
  if (isTablet) return "tablet";
  if (isMobile) return "mobile";

  return "pc";
};
