import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavBack = styled.div<{ $sidebar: boolean }>`
  background: #707070;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ $sidebar }) => ($sidebar ? "0" : "-150%")};
  transition: 350ms;
  z-index: 10;
`;

export const Nav = styled.div`
  background: #ffffff;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  //overflow: auto;
  //overflow-y: auto;
  @media (min-width: 1024px) {
    height: 25.814vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    height: 23.3073vw;
  }
  @media (max-width: 767px) {
    height: 25.814vw;
  }
`;

export const NavIcon = styled(Link)`
  //height: 11.1628vw;
  @media (min-width: 1024px) {
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-left: 10.026vw;
    width: 10.4167vw;
    height: 10.4167vw;
  }
  @media (max-width: 767px) {
    margin-left: 3.7209vw;
    width: 11.1628vw;
    height: 11.1628vw;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NavIcon2 = styled(NavIcon)`
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 13.6719vw;
    height: 12.8906vw;
  }
  @media (max-width: 767px) {
    width: 14.6047vw;
    height: 13.7209vw;
  }

  margin-left: 63.7209vw;
  font-size: 5.5814vw;
  margin-bottom: 13.4884vw;
`;

export const SidebarNav = styled.nav<{ $sidebar: boolean }>`
  background: #ffffff;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  position: fixed;
  overflow-y: auto;
  box-sizing: border-box;
  @media (min-width: 1024px) {
    width: 28.9063vw;
    // overflow: auto;
    //margin-bottom: 5.0313vw;
    //  padding-bottom: 5.0313vw;
  }
  @media (max-width: 1023px) {
    width: 74.4186vw;
    top: 0;
    left: ${({ $sidebar }) => ($sidebar ? "0" : "-150%")};
    transition: 350ms;
    z-index: 10;
  }
`;

export const SidebarWrap = styled.div`
  @media (min-width: 1024px) {
    height: calc(
      100vh - 4.7396vw
    ); //100vh; /* 전체 뷰포트 높이를 차지하도록 설정 */
    overflow-y: auto; /* 세로 방향으로 내용이 넘칠 경우 스크롤바 표시 */
    width: 10.1563vw;
    margin-left: 9.375vw;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const MainIcon = styled(Link).attrs({ to: "/" })`
  @media (min-width: 1024px) {
    display: block;
    margin-bottom: 4.7396vw;
    width: 5.5vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    align-items: center;
    margin-left: 22.6563vw;
    width: 13.6719vw;
    height: 12.8906vw;
  }
  @media (max-width: 767px) {
    align-items: center;
    margin-left: 27.907vw;
    width: 14.6047vw;
    height: 13.7209vw;
  }
`;

export const SidebarLink = styled(Link)<{ $itemNav: boolean }>`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  font-weight: 500;
  text-align: left;
  color: #222222;

  @media (min-width: 1024px) {
    // flex-direction: column;
    align-items: center;
    margin-top: 2.0313vw;
    height: 1.4583vw;
    font-size: 1.0417vw; // 1.25vw;
    line-height: 1.25vw; //1.4063vw; //1.875vw;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (max-width: 1023px) {
    align-items: center;
    padding-left: 9.0698vw;
    height: 14.6512vw;
    font-size: 5.5814vw;
    line-height: 7.4419vw;

    border-bottom: 0.5px solid
      ${(props) => (props.$itemNav ? "#eeeeee" : "#707070")};
    background-color: ${(props) => (props.$itemNav ? "#eeeeee" : "#ffffff")};
  }

  //background-color:red;
`;

export const SidebarLabel = styled.span`
  font-weight: 600;
  color: #222;

  @media (min-width: 1024px) {
    font-size: 0.7292vw;
    line-height: 1.35;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    // margin-left: 9.0698vw;
    font-size: 2.6042vw;
    line-height: 7.4419vw;
    height: 14.6512vw;
    color: #9e9e9e;
  }
  @media (max-width: 767px) {
    line-height: 1.35;
    font-size: 4.6512vw;
    color: #9e9e9e;
  }
`;

export const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #9e9e9e;

  @media (min-width: 1024px) {
    margin-top: 0.2083vw;
    span {
      font-size: 0.7292vw;
      text-align: left;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: red;
      }
    }
  }
  @media (max-width: 1023px) {
    padding-left: 9.0698vw;
    background: #eeeeee;
    font-size: 4.6512vw;
  }
`;

export const DropIconView = styled.div`
  @media (min-width: 1024px) {
    margin-right: 0.625vw;
  }
  @media (max-width: 1023px) {
    margin-right: 4.9767vw;
  }
`;
