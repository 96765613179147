import React from "react";
import { TabMenuView } from "./style";

interface TabMenuProps {
  currentTab: number;
  menuArr: { name: string }[];
  onClickTab: (index: number) => void;
}

const TabMenu: React.FC<TabMenuProps> = ({
  currentTab,
  menuArr,
  onClickTab,
}) => {
  return (
    <TabMenuView className="text_wrap">
      {menuArr.map((el, index) => (
        <li
          key={index}
          className={index === currentTab ? "submenu focused" : "submenu"}
          onClick={() => onClickTab(index)} // 탭 클릭 시 상위로 선택된 인덱스 전달
        >
          {el.name}
        </li>
      ))}
    </TabMenuView>
  );
};

export default TabMenu;
