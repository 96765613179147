import { useState } from "react";
import { TabletMobile, Desktop } from "../../Device";
import { SidebarLink, SidebarLabel, DropdownLink, DropIconView } from "./Navtx";

//{(item.subNav && showSubnav) ? null : item.subNav ? null : sendDataToParent}
const SubMenu = ({ item, ondatachange }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  const sendDataToParent = () => {
    ondatachange(); // 콜백 함수 호출하여 데이터 전달
  };

  return (
    <>
      <TabletMobile>
        <SidebarLink
          $itemNav={item.subNav && subnav}
          to={item.path}
          onClick={item.subNav ? item.subNav && showSubnav : sendDataToParent}
        >
          {item.title}
          <DropIconView>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </DropIconView>
        </SidebarLink>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <DropdownLink
                to={item.path}
                key={index}
                onClick={sendDataToParent}
              >
                <SidebarLabel>{item.title}</SidebarLabel>
              </DropdownLink>
            );
          })}
      </TabletMobile>
      <Desktop>
        <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
          {item.title}
          <DropIconView>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </DropIconView>
        </SidebarLink>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <DropdownLink to={item.path} key={index}>
                <span>{item.title}</span>
              </DropdownLink>
            );
          })}
      </Desktop>
    </>
  );
};

export default SubMenu;
