import React from "react";
import { styled } from "styled-components";
import { Default, Mobile } from "../Device";
import { handleOpenNewTab } from "../screens/common";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: -50px;
  left: 0;
  z-index: 1000;
`;

const ModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 10%;
  width: 500px;
  // height: 500px;
  z-index: 1001;
  border: 2px solid #222; /* 외곽선 색상과 두께 */
  border-radius: 2px; /* 테두리 둥글게 */
`;

const MobileModalContainer = styled(ModalContainer)`
  width: 300px;
  //height: 300px;
  border: 2px solid #222; /* 외곽선 색상과 두께 */
  border-radius: 2px; /* 테두리 둥글게 */
`;

const ModalContent = styled.div`
  width: 100%;
  background-color: #fff;
  position: relative;
`;
const ModalCloseContent = styled.div`
  clear: both;
  width: 100%;
  background-color: #fff;
`;

const ModalBaseCloseWrapper = styled.div`
  margin-left: 3.5%;
  margin-right: 3.5%;
  display: flex;
  //  justify-content: space-between;
  justify-content: end;
  align-items: center;
  color: #000;
  p {
    cursor: pointer;
  }
`;

const ModalCloseWrapper = styled(ModalBaseCloseWrapper)`
  justify-content: space-between;
`;

interface IPopupProps {
  closePopup: () => void;
  //closePopupUntilExpires: () => void;
  clicklink: string;
  imglink: string;
}
interface IPopupImageButton2Props {
  closePopup: () => void;
  clicklink1: string;
  clicklink2: string;
  imglink: string;
}

function Popup({ closePopup, clicklink, imglink }: IPopupProps) {
  console.log(clicklink, imglink);

  return (
    <Container>
      <ModalBackground />
      <Mobile>
        <MobileModalContainer>
          <ModalContent onClick={() => handleOpenNewTab(clicklink)}>
            <img src={imglink} alt="popuptest" width={"100%"} />
          </ModalContent>
          <ModalCloseContent>
            <ModalBaseCloseWrapper>
              <p onClick={closePopup}>닫기</p>
            </ModalBaseCloseWrapper>
          </ModalCloseContent>
        </MobileModalContainer>
      </Mobile>

      <Default>
        <ModalContainer>
          <ModalContent onClick={() => handleOpenNewTab(clicklink)}>
            <img src={imglink} alt="popuptest" width={"100%"} />
          </ModalContent>
          <ModalCloseContent>
            <ModalBaseCloseWrapper>
              <p onClick={closePopup}>닫기</p>
            </ModalBaseCloseWrapper>
          </ModalCloseContent>
        </ModalContainer>
      </Default>
    </Container>
  );
}
//    광교

const PopupButton = styled.div`
  position: absolute;
  width: 37.3333%;
  height: 6.5556%;
  border-radius: 1.7778vw;
  bottom: 2.5556%;
  border-radius: 1.7778vw;
  background-color: #f70000;

  font-family: Pretendard;
  font-size: 3.4444rm;
  line-height: 1.26;
  display: flex;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
  text-align: center;

  color: #fff;

  cursor: pointer;
`;

const LeftButton = styled(PopupButton)`
  left: 8.3333%;
`;

const RightButton = styled(PopupButton)`
  right: 8.3333%;
`;

export function PopupImageButton2({
  closePopup,
  clicklink1,
  clicklink2,
  imglink,
}: IPopupImageButton2Props) {
  console.log(clicklink1, clicklink2, imglink);

  return (
    <Container>
      <ModalBackground />
      <Mobile>
        <MobileModalContainer>
          <ModalContent>
            <img src={imglink} alt="popuptest" width={"100%"} />
            <LeftButton onClick={() => handleOpenNewTab(clicklink1)}>
              한남 신청하기
            </LeftButton>
            <RightButton onClick={() => handleOpenNewTab(clicklink2)}>
              광교 신청하기
            </RightButton>
          </ModalContent>
          <ModalCloseContent>
            <ModalBaseCloseWrapper>
              <p onClick={closePopup}>닫기</p>
            </ModalBaseCloseWrapper>
          </ModalCloseContent>
        </MobileModalContainer>
      </Mobile>

      <Default>
        <ModalContainer>
          <ModalContent>
            <img src={imglink} alt="popuptest" width={"100%"} />
            <LeftButton onClick={() => handleOpenNewTab(clicklink1)}>
              한남 신청하기
            </LeftButton>
            <RightButton onClick={() => handleOpenNewTab(clicklink2)}>
              광교 신청하기
            </RightButton>
          </ModalContent>
          <ModalCloseContent>
            <ModalBaseCloseWrapper>
              <p onClick={closePopup}>닫기</p>
            </ModalBaseCloseWrapper>
          </ModalCloseContent>
        </ModalContainer>
      </Default>
    </Container>
  );
}
export default Popup;
