import { useState } from "react";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import { TabletMobile, Desktop } from "../../Device";
import {
  NavBack,
  Nav,
  SidebarNav,
  MainIcon,
  SidebarWrap,
  NavIcon,
  NavIcon2,
} from "./Navtx";

import NetworkManager from "../../Network/NetworkManager";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const closesidebar = () => setSidebar(false);
  const goMain = () => window.location.replace("/");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const logintest = async () => {
    try {
      const data = await NetworkManager.login("test1@mail.com", "1010");
      setIsLoading(false);
      alert("로그인 성공! 토큰: " + data.token); // 응답 데이터 구조에 따라서 data.token 수정 필요
    } catch (err) {
      setIsLoading(false);
      setError("로그인 실패: " + err);
      console.error(err);
    }
  };

  return (
    <>
      <TabletMobile>
        <IconContext.Provider value={{ color: "#000" }}>
          <Nav>
            <NavIcon to="#">
              <img
                src="https://playsongshome.playsongs.ai/charlie/icon/menu-fill.png"
                alt=""
                width={"100%"}
                onClick={showSidebar}
              />
            </NavIcon>
            <MainIcon to="/" onClick={goMain}>
              <img
                src="https://playsongshome.playsongs.ai/logo/logo-menu-charlie.png"
                alt="mainicon"
                width={"100%"}
              />
            </MainIcon>
          </Nav>

          <NavBack $sidebar={sidebar} />
          <SidebarNav $sidebar={sidebar} onChange={closesidebar}>
            <SidebarWrap>
              <NavIcon2 to="#">
                <AiIcons.AiOutlineClose onClick={showSidebar} />
              </NavIcon2>
              {SidebarData.map((item, index) => {
                return (
                  <SubMenu
                    item={item}
                    key={index}
                    ondatachange={closesidebar}
                  />
                );
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </TabletMobile>
      <Desktop>
        <SidebarNav $sidebar={true} onChange={closesidebar}>
          <SidebarWrap>
            <MainIcon to="/" onClick={goMain}>
              <img
                src="https://playsongshome.playsongs.ai/logo/logo-menu-charlie.png"
                alt="mainicon"
                width={"100%"}
              />
            </MainIcon>
            {SidebarData.map((item, index) => {
              return (
                <SubMenu item={item} key={index} ondatachange={closesidebar} />
              );
            })}
          </SidebarWrap>
        </SidebarNav>
      </Desktop>
    </>
  );
};

export default Sidebar;
