interface IBasicInfo {
  pc: string;
  tablet: string;
  mobile: string;
}

interface IFooterString {
  title: string;
  description: IBasicInfo;
  copy: string;
}

interface IAppDecriptionInfo {
  title: string;
  desc: string;
}
interface IAppService {
  title: string;
  homeDecription: IAppDecriptionInfo;
  partyDecription: IAppDecriptionInfo;
  download: string;
}
interface IAboutUsInfo {
  e: string;
  k: string;
}
interface IAboutUs {
  title: string;
  description1: IAboutUsInfo;
  description2: IAboutUsInfo;
  description3: IAboutUsInfo;
}
interface IAboutUsOurValues {
  title: string;
  description: IAboutUsInfo;
}
interface ILearningInfo {
  title: string;
  k: string;
}

interface ILearning {
  language: ILearningInfo;
  languagePartner: string;
  number: ILearningInfo;
  arts: ILearningInfo;
  afterschool: ILearningInfo;
}
export class StringManager {
  static footerString: IFooterString = {
    title: "Bicycle Co., Ltd\n",
    description: {
      pc: "CEO: Lee Do-hee | Business Number: 114-86-61839 (Republic of Korea) | Tel. 1668-0822\nAddress: 98, Dokseodang-ro, Yongsan-gu, Seoul | Chief privacy officer: Young-Ju An Info @playsongs.co.kr",
      tablet:
        "CEO: Lee Do-hee\nBusiness Number: 114-86-61839 (Republic of Korea)\nTel. 1668-0822 Address: 98, Dokseodang-ro, Yongsan-gu, Seoul\nChief privacy Officer: Young-Ju An Info @playsongs.co.kr",
      mobile:
        "CEO: Lee Do-hee | Business Number: 114-86-61839\n(Republic of Korea) | Tel. 1668-0822\nAddress: 98, Dokseodang-ro, Yongsan-gu, Seoul\nChief privacy Officer: Young-Ju An Info @playsongs.co.kr",
    },
    copy: "© BICYCLE Co.,Ltd. ALL RIGHTS RESERVED.",
  };

  static appserviceString: IAppService = {
    title: "APP SERVICE",
    download: "앱 다운받으러 가기",
    homeDecription: {
      title: "Playsongshome",
      desc: "6만 명 이상 고객이 선택한 프로그램 플레이송스를 집에서!\n음악치료사들이 기획한! 유일한 음악중심 통합발달 프로그램!\n미국 엄마들에게도 인정받은 '맘스초이스 어워드' 골드 메달!\n영상과 교구재 멀티링크로 더욱 효과적인 프로그램.\n국어로 한번 영어로 한번 자연스러운 첫 영어 만남.\n월령별 발달에 맞는 체계적인 놀이법과 기획된 음악을 통해 아이들은 \n신나게 놀면서도 밸런스 있게 발달하고 인지능력을 키웁니다.\n2007년부터 현장 경험과 데이터 기반으로 만들어진 프로그램 입니다.",
    },
    partyDecription: {
      title: "Playsong's Party",
      desc: "플레이송스 사운드북을 통해 음악으로 배우고 학습하여\n아이의 사고력과 창의성을 증진시키기 위한 놀이학습 프로그램",
    },
  };

  static homeTitleString: IBasicInfo = {
    pc: "WELCOME to Charlie’s Big Red House!",
    tablet: "WELCOME to Charlie’s Big Red House!",
    mobile: "WELCOME to \nCharlie’s Big Red House!",
  };
  static homePart1String: IBasicInfo = {
    pc: "Charlie’s Big Red House was established in 2012 based on the belief that “children should be respected and loved while experiencing joy and happiness through learning”. Drawing on our extensive experience, we continuously engage in research and education, providing effective bilingual education through the International Early Years Curriculum (IEYC) and our enhanced self-developed Korean curriculum. Additionally, we offer Arts/Cultural programs that allow children to enjoy seasonal garden activities in a nature-friendly environment and appreciate the beauty of art. Charlie’s Big Red House values the interaction between teachers and children, as well as collaboration between the school and families, and we remain committed to ongoing research and education in bilingual and cultural education.",
    tablet:
      "Charlie’s Big Red House was established in 2012 based on the belief that “children should be respected and loved while experiencing joy and happiness through learning”. Drawing on our extensive experience, we continuously engage in research and education, providing effective bilingual education through the International Early Years Curriculum (IEYC) and our enhanced self-developed Korean curriculum. Additionally, we offer Arts/Cultural programs that allow children to enjoy seasonal garden activities in a nature-friendly environment and appreciate the beauty of art. Charlie’s Big Red House values the interaction between teachers and children, as well as collaboration between the school and families, and we remain committed to ongoing research and education in bilingual and cultural education.",
    mobile:
      "Charlie’s Big Red House was established in 2012 based on the belief that “children should be respected and loved while experiencing joy and happiness through learning”. Drawing on our extensive experience, we continuously engage in research and education, providing effective bilingual education through the International Early Years Curriculum (IEYC) and our enhanced self-developed Korean curriculum. Additionally, we offer Arts/Cultural programs that allow children to enjoy seasonal garden activities in a nature-friendly environment and appreciate the beauty of art. Charlie’s Big Red House values the interaction between teachers and children, as well as collaboration between the school and families, and we remain committed to ongoing research and education in bilingual and cultural education.",
  };
  static homePart1KorString: IBasicInfo = {
    pc: `찰리스빅레드하우스는 "아이는 존중과 사랑을 받으며 배움을 통해 즐거움과 행복을 느껴야 한다"는 신념을 바탕으로 2012년에 설립되었습니다. 오랜 경험을 바탕으로 지속적인 연구, 교육이 이어 나가고 있으며 효율적인 이중언어 교육을 위해 국제 유아 교육커리큘럼인 IEYC와 강화된 자체 한글 개발 커리큘럼 프로그램을 제공하고 있습니다.\n또한, 친자연적 환경에서 사계절 가든 활동을 즐기며 예술의 아름다움을 느낄 수 있는 문화 프로그램도 개발하여 실시하고 있습니다.\n찰리스빅레드하우스는 교사와 아이의 상호작용, 원과 가정의 협력을 소중하게 여기며 계속해서 이중언어교육과 문화, 예술 교육에 대한 심도 있는 연구와 교육을 이어 나아가고 있습니다.`,
    tablet: `찰리스빅레드하우스는 "아이는 존중과 사랑을 받으며 배움을 통해 즐거움과 행복을 느껴야 한다"는 신념을 바탕으로 2012년에 설립되었습니다. 오랜 경험을 바탕으로 지속적인 연구, 교육이 이어 나가고 있으며 효율적인 이중언어 교육을 위해 국제 유아 교육커리큘럼인 IEYC와 강화된 자체 한글 개발 커리큘럼 프로그램을 제공하고 있습니다.\n또한, 친자연적 환경에서 사계절 가든 활동을 즐기며 예술의 아름다움을 느낄 수 있는 문화 프로그램도 개발하여 실시하고 있습니다.\n찰리스빅레드하우스는 교사와 아이의 상호작용, 원과 가정의 협력을 소중하게 여기며 계속해서 이중언어교육과 문화, 예술 교육에 대한 심도 있는 연구와 교육을 이어 나아가고 있습니다.`,
    mobile: `찰리스빅레드하우스는 "아이는 존중과 사랑을 받으며 배움을 통해 즐거움과 행복을 느껴야 한다"는 신념을 바탕으로 2012년에 설립되었습니다. 오랜 경험을 바탕으로 지속적인 연구, 교육이 이어 나가고 있으며 효율적인 이중언어 교육을 위해 국제 유아 교육커리큘럼인 IEYC와 강화된 자체 한글 개발 커리큘럼 프로그램을 제공하고 있습니다.\n또한, 친자연적 환경에서 사계절 가든 활동을 즐기며 예술의 아름다움을 느낄 수 있는 문화 프로그램도 개발하여 실시하고 있습니다.\n찰리스빅레드하우스는 교사와 아이의 상호작용, 원과 가정의 협력을 소중하게 여기며 계속해서 이중언어교육과 문화, 예술 교육에 대한 심도 있는 연구와 교육을 이어 나아가고 있습니다.`,
  };

  static homePart2info1String: IBasicInfo = {
    pc: `Our program follows the `,
    tablet: `Our program follows the `,
    mobile: "Our program follows \nthe ",
  };

  static homePart2info2String: IBasicInfo = {
    pc: `International Early Years Curriculum (IEYC). \n`,
    tablet: `International Early Years Curriculum (IEYC). \n`,
    mobile: `International Early Years Curriculum (IEYC). \n`,
  };

  static homePart2info3String: IBasicInfo = {
    pc: `This is an innovative research- based framework built on international best practice and the developmental needs of preschoolers. It has eight essential Learning Principles and a structured process that captures children’s natural curiosity as a starting point.`,
    tablet: `This is an innovative research- based framework built on international best practice and the developmental needs of preschoolers. It has eight essential Learning Principles and a structured process that captures children’s natural curiosity as a starting point.`,
    mobile:
      "This is an innovative research- based framework built on international best practice and the developmental needs of preschoolers. It has eight essential Learning Principles and a structured process that captures children’s natural curiosity as a starting point.",
  };

  static homePart2info1KorString: IBasicInfo = {
    pc: "International Early Years Curriculum (IEYC)",
    tablet: "International Early Years Curriculum (IEYC)",
    mobile: "International Early Years Curriculum (IEYC)",
  };
  static homePart2info2KorString: IBasicInfo = {
    pc: "는 유아의 아동 발달적 요구에 맞춘 혁신적인 연구 기반의 커리큘럼입니다. 국제적인 모범 사례를 바탕으로 하며, 여덟 가지 중요한 학습 원칙을 가지고 있습니다. 또한, 아이들의 자연스러운 호기심을 출발점으로 삼아 학습 과정을 체계적으로 설계합니다.",
    tablet:
      "는 유아의 아동 발달적 요구에 맞춘 혁신적인 연구 기반의 커리큘럼입니다. 국제적인 모범 사례를 바탕으로 하며, 여덟 가지 중요한 학습 원칙을 가지고 있습니다. 또한, 아이들의 자연스러운 호기심을 출발점으로 삼아 학습 과정을 체계적으로 설계합니다.",
    mobile:
      "는 유아의 아동 발달적 요구에 맞춘 혁신적인 연구 기반의 커리큘럼입니다. 국제적인 모범 사례를 바탕으로 하며, 여덟 가지 중요한 학습 원칙을 가지고 있습니다. 또한, 아이들의 자연스러운 호기심을 출발점으로 삼아 학습 과정을 체계적으로 설계합니다.",
  };

  static aboutusString: IAboutUs = {
    title: "Our Mission",
    description1: {
      e: "Understanding the holistic growth and development",
      k: "전인교육을 통해 신체적, 정서적, 사회적, 지적 성장을 통합적으로 이해 ",
    },
    description2: {
      e: "Individualized teaching and learning based on personality, interests, and needs",
      k: "아이의 기질, 관심, 요구를 반영한 맞춤형 학습을 통해 아이의 성장을 촉진함",
    },
    description3: {
      e: "Warm and responsive student-teacher interactions",
      k: "아이와 교사 간의 따뜻하고 안정된 상호작용을 통해 신뢰와 안전감을 형성함",
    },
  };

  static aboutusOurValues: IAboutUsOurValues = {
    title: "Our Values",
    description: {
      e: "Charlie's Big Red House is a bilingual educational institution that emphasizes respect for children, individualized development, and high-quality interactions.\nOur learning is an inquiry-based, bilingual approach to education, encouraging children to think critically from diverse perspectives. The program fosters a joyful, warm, and stimulating learning environment, where students can enjoy their first educational experiences while growing into confident, caring individuals.",
      k: "찰리스빅레드하우스는 아동 존중, 개별적 발달, 그리고 양질의 상호작용을 강조하는 이중언어 교육 기관입니다. \n저희 학습은 탐구 기반의 이중언어 접근 방식을 통해 다양한 관점에서 비판적으로 사고하도록 아이들을 격려합니다. \n이 프로그램은 즐겁고 따뜻하며 자극적인 학습 환경을 조성하여 학생들이 자신들의 첫 교육 경험을 즐기고, 자신감 있고 배려심 있는 개인으로 성장할 수 있도록 돕습니다.",
    },
  };

  static learningLanguageString: ILearning = {
    languagePartner:
      "찰리의 한글 교재 찰리랑 한글은 어린이 교육콘텐츠의 발전을 응원하는 산돌서체를 지원 받아 사용하였습니다.",
    language: {
      title: "Language Arts/Literacy",
      k: `아이들이 이중언어를 배우는 과정에서 새로운 언어를 배우는 기쁨을 경험하며 자신감 있는 글로벌 시민으로 성장합니다. 읽기, 쓰기, 말하기에 중점을 둔 이중언어 노출을 통해 자연스러운 언어 발달이 이루어집니다. 찰리스빅레드하우스 에서는 IEYC 커리큘럼을 바탕으로 다양한 영어 활동에 학생들이 몰입할 수 있도록 주제 기반 접근법으로 영어를 가르칩니다.\n한글 수업은 유아기의 기초 문해력 향상을 위해 수년간 연구하고 개발된 한글 커리큘럼을 바탕으로 만들어진 "찰리랑 한글" 교재를 사용합니다. "찰리랑 한글"은 어린이 교육 콘텐츠 발전을 위해 산돌구름 '산돌' 폰트 협찬을 받아 제작되었으며, 유아기의 언어 발달 과정과 한글 창제 원리를 기반으로 설계되었습니다. 이 교재는 한글에 관심이 있는 유아들이 자음과 모음에 대한 지식을 얻도록 도와주며, 재미있고 흥미로운 활동을 통해 글자를 읽고 쓰는 목표를 달성할 수 있도록 합니다.`,
    },

    number: {
      title: "Numbers and Sensory",
      k: "논리적 사고와 탐구 기반 학습의 원칙, 그리고 전통적인 교과서 기반 학습을 결합하면, 다양한 학습자의 요구를 충족시키는 균형 잡힌 교육과정이 만들어집니다. 이 균형 잡힌 접근 방식은 학생들이 탐구를 통해 개념을 탐색하고 발견할 뿐만 아니라 교과서에서 체계적인 지식을 습득하여 숫자, 계산, 도형, 측정, 규칙성, 확률 및 통계와 같은 필수적인 수학 개념에 대한 전반적인 이해를 발전시킬 수 있도록 돕습니다.",
    },
    arts: {
      title: "Arts and Music",
      k: "",
    },
    afterschool: {
      title: "After School Class",
      k: "",
    },
  };
}

export const renderTextWithSpans = (type: string) => {
  if (type === "language") {
    return (
      <>
        Children who engage in bilingualism experience the joy of learning a new
        language and grow into confident global citizens. Natural language
        development occurs through <span>bilingual exposure</span> that
        emphasizes <span>reading, writing, and speaking</span>. At Charlie’s Big
        Red House, English is taught using a theme-based approach where students
        are immersed in various English activities aligned with the{" "}
        <span>IEYC curriculum.</span>
        <br />
        Korean classes use the <span>"Charlie and Hangeul" textbook,</span>{" "}
        which is based on a curriculum that has been developed and researched
        over several years to enhance early childhood foundational literacy.
        "Charlie and Hangeul," created with support from ‘Sandoll Typography’ to
        contribute to the development of children’s educational content, is
        designed around the language development process of early childhood and
        the principles of Hangeul creation. The textbook helps young children
        interested in Hangeul gain knowledge of Korean consonants and vowels.
        Through engaging and fun activities, it aims to help children achieve
        the goal of reading and writing letters.
      </>
    );
  } else if (type === "number") {
    return (
      <>
        Combining <span>logical thinking</span> with the principles of{" "}
        <span>inquiry-based learning</span> and traditional{" "}
        <span>textbook-based learning</span> creates a balanced curriculum that
        addresses the diverse needs of young learners. This balanced approach
        ensures that students not only explore and discover concepts through
        inquiry but also gain structured knowledge from textbooks, helping them
        develop a well-rounded understanding of essential math concepts like
        numbers, computations, shapes, measurements, regularity, probability,
        and statistics.
      </>
    );
  } else if (type === "art") {
    return (
      <>
        Art classes and cultural experiences significantly enhance children's{" "}
        <span>imagination</span> and <span>creativity</span> while broadening
        their understanding of the world around them. These activities help
        young learners express themselves freely, nurturing their creativity and
        original thinking. By engaging in artistic tasks, children develop fine{" "}
        <span>motor skills, hand-eye coordination, and muscle control, </span>
        all of which are essential for writing and other future skills. Art also
        provides an outlet for emotional expression, allowing children to{" "}
        <span>communicate</span> feelings they may not yet have the words for,
        thereby <span>promoting emotional intelligence.</span> Throughout the
        year, students engage in visits to art and cultural museums and
        participate in exhibitions and performances of their own.
      </>
    );
  } else if (type === "art k") {
    return (
      <>
        미술 수업과 문화 체험은 아이들의 상상력과 창의력을 크게 향상하게 시키며,
        주변 세계에 대한 이해를 넓히는 데 도움을 줍니다.
        <br /> 이러한 활동은 어린 학습자들이 자유롭게 자신을 표현할 수 있도록
        도와주어, 그들의 창의성과 독창적인 사고를 키우는 데 기여합니다. 미술
        활동에 참여함으로써 아이들은 신체 조절 능력, 미세 운동 능력 및 근육 조절
        능력을 개발하게 되며, 이는 글쓰기 및 다른 미래의 기술에 필수적입니다.
        또한 미술은 감정 표현의 기회를 제공하여, 아이들이 아직 말할 수 없는
        감정을 소통할 수 있게 하여, 감정 지능을 증진하게 시킵니다. 연중 학생들은
        미술 및 문화 박물관을 방문하고, 자신의 전시회 및 공연에 참여합니다.
      </>
    );
  } else if (type === "afterschool") {
    return (
      <>
        We believe that extended learning should be exciting and engaging! Our
        after-school program is designed to spark curiosity, foster creativity,
        and cultivate a love for learning in children. We offer a safe and{" "}
        <span>nurturing environment</span> where kids can{" "}
        <span>explore, discover,</span> and <span>grow after</span> their school
        day.
      </>
    );
  } else if (type === "afterschool k") {
    return (
      <>
        찰리스빅레드하우스 방과 후 프로그램은 아이들의 호기심을 자극하고,
        창의성을 키우며, 다양한 배움에 대한 열정을 가질 수 있기를 권장합니다.
        아이들이 학교 수업 후 탐구하고 발견하며 성장할 수 있는 안전하고 보살피는
        환경을 제공합니다.
      </>
    );
  } else if (type === "languagePartner") {
    return (
      <>
        찰리의 한글 교재 '찰리랑 한글'은 어린이 교육콘텐츠의 발전을 응원하는{" "}
        <span>산돌서체</span>를 지원 받아 사용하였습니다.
      </>
    );
  }
  return <></>;
};
