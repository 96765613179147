import { StringManager } from "../../assets/StringManager";
import { Desktop } from "../../Device";
import {
  BodyView,
  PathStringView,
  AUMVTitleView,
  AUMVTitle2View,
  AUMVDescriptionView0,
  AUMVDescriptionView,
  AUMVDescriptionView2,
  AUMVImgView,
  AUMVDescriptionEView,
} from "../common";

function MissionandValue() {
  return (
    <div>
      <BodyView>
        <Desktop>
          <PathStringView className="path">
            About Us {">"} Mission and Value
          </PathStringView>
          <div className="separatorline" />
        </Desktop>
        <AUMVTitle2View>{StringManager.aboutusString.title}</AUMVTitle2View>
        <AUMVDescriptionView $first={true} className="text_wrap">
          {StringManager.aboutusString.description1.e}
        </AUMVDescriptionView>
        <AUMVDescriptionView2>
          {StringManager.aboutusString.description1.k}
        </AUMVDescriptionView2>
        <AUMVDescriptionView $first={false} className="text_wrap">
          {StringManager.aboutusString.description2.e}
        </AUMVDescriptionView>
        <AUMVDescriptionView2>
          {StringManager.aboutusString.description2.k}
        </AUMVDescriptionView2>
        <AUMVDescriptionView $first={false} className="text_wrap">
          {StringManager.aboutusString.description3.e}
        </AUMVDescriptionView>
        <AUMVDescriptionView2>
          {StringManager.aboutusString.description3.k}
        </AUMVDescriptionView2>

        <AUMVTitleView>{StringManager.aboutusOurValues.title}</AUMVTitleView>
        <AUMVDescriptionEView className="text_wrap">
          {StringManager.aboutusOurValues.description.e}
        </AUMVDescriptionEView>
        <AUMVDescriptionView0 className="text_wrap">
          {StringManager.aboutusOurValues.description.k}
        </AUMVDescriptionView0>
        <AUMVImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/aboutus/misson-1%403x.png"
            alt="img"
            width={"100%"}
          />
        </AUMVImgView>
      </BodyView>
    </div>
  );
}

export default MissionandValue;

//
