import {
  handleOpenNewTab,
  BodyView,
  PathStringView,
  BannerView,
  FamilySiteDescription,
  FamilySiteInsta,
  FamilySiteInstaImg,
  FamilySiteInstaLine,
  FamilySiteLogoLineBycycle,
  FamilySiteLogoLineC,
  FamilySiteLogoLinePH,
  FamilySiteLogoLineSection,
  FamilySiteZone,
  FamilySitesection,
  LearningPageInfoImgView,
  FamilySiteLogoLineArrow,
  FamilySiteLogoLine,
  FamilySiteLogoLinePHImg,
  FamilySiteLogoLineCImg,
  FamilySiteLogoLinePImg,
  ASTitle,
  ASSection,
  ASSectionDescription,
  ASSectionImage,
  ASSectionTitle,
  ASSectionTextZone,
  ASSectionDownLoadZone,
  ASSectionDownLoadTitle,
  ASSectionDownLoadArrowImage,
  ASSectionDownLoadIconImage,
  ASSectionDownLoadIconImage2,
  ASSection2,
} from "./common";
import { Default, Desktop, Mobile, Tablet } from "../Device";
import { StringManager } from "../assets/StringManager";
import { useScreenType } from "../Componets/Devicets";
interface ASDownLoadProps {
  google: string;
  app: string;
}

interface ASAppInfoProps {
  title: string;
  desc: string;
  img: string;
  alt: string;
}

export default function FamilySite() {
  return (
    <>
      <Desktop>
        <DesktopFamilySiteView />
      </Desktop>
      <Tablet>
        <TabletFamilySiteView />
      </Tablet>
      <Mobile>
        <MobileFamilySiteView />
      </Mobile>
    </>
  );
}

function SiteInfoView() {
  return (
    <>
      <FamilySiteZone>
        <Bicycle />
        <Playsongs />
        <PlaysongsHome />
        <Charlie />
      </FamilySiteZone>
      <AppService />
    </>
  );
}

function DesktopFamilySiteView() {
  return (
    <BodyView className="text_wrap">
      <PathStringView className="path">Our Service</PathStringView>
      <div className="separatorline" />
      <BannerView>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/familysite/pc/banner.png"
          alt="banner-family-desktop"
          width={"100%"}
        />
      </BannerView>
      <SiteInfoView />
    </BodyView>
  );
}

function TabletFamilySiteView() {
  return (
    <BodyView className="text_wrap">
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/familysite/tablet/banner2.png"
          alt="banner-family-pad"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <SiteInfoView />
    </BodyView>
  );
}

function MobileFamilySiteView() {
  return (
    <>
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/playsongs/familysite/mobile/banner.png"
          alt="banner-family-pad"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <BodyView className="text_wrap">
        <SiteInfoView />
      </BodyView>
    </>
  );
}
function Bicycle() {
  return (
    <FamilySitesection>
      <FamilySiteLogoLineBycycle>
        <FamilySiteLogoLine>
          <FamilySiteLogoLinePHImg
            onClick={() =>
              handleOpenNewTab("http://www.playsongs.co.kr/bicycle/")
            }
            src="https://playsongshome.playsongs.ai/logo/logo-bicycle.png"
            alt="FamilySiteLogoLine"
            width={"100%"}
          />
          <FamilySiteLogoLineArrow>
            <img
              src="https://playsongshome.playsongs.ai/icon/arrow.png"
              alt="FamilySiteLogoLineArrow"
              width={"100%"}
            />
          </FamilySiteLogoLineArrow>
        </FamilySiteLogoLine>
        <span>바이시클</span>
      </FamilySiteLogoLineBycycle>

      <FamilySiteDescription className="text_wrap">
        아이들이 몰입 할 수 있는 최고의 이중언어 놀이, 탐구 교육과 더불어 세상의
        가치를 만드는 기업​.{"\n"}행복한 아이들의 가능성을 여는 교육 콘텐츠를
        만듭니다.
      </FamilySiteDescription>
    </FamilySitesection>
  );
}

function Playsongs() {
  return (
    <FamilySitesection>
      <FamilySiteLogoLineSection>
        <FamilySiteLogoLinePH>
          <FamilySiteLogoLine>
            <FamilySiteLogoLinePImg
              onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
              src="https://playsongshome.playsongs.ai/logo/logo.png"
              alt=""
              width={"100%"}
            />
            <FamilySiteLogoLineArrow>
              <img
                src="https://playsongshome.playsongs.ai/icon/arrow.png"
                alt="FamilySiteLogoLineArrow"
                width={"100%"}
              />
            </FamilySiteLogoLineArrow>
          </FamilySiteLogoLine>
          <span>플레이송스</span>
        </FamilySiteLogoLinePH>
        <FamilySiteInstaImg
          onClick={() =>
            handleOpenNewTab("https://www.instagram.com/playsongs_official/")
          }
          src="https://playsongshome.playsongs.ai/icon/insta.png"
          alt=""
          width={"100%"}
        />
      </FamilySiteLogoLineSection>
      <FamilySiteDescription className="text_wrap">
        음악·놀이·스토리로 잠재력을 깨우는 통합교육 프로그램입니다.{"\n"}
        영유아기의 세부적인 발달 과정을 고려하여 아이가 능동적으로 사고하고,
        놀이하고, 춤추고, 상상하고, 운동할 수 있게 기획되었습니다.
      </FamilySiteDescription>
    </FamilySitesection>
  );
}

function PlaysongsHome() {
  return (
    <FamilySitesection>
      <FamilySiteLogoLineSection>
        <FamilySiteLogoLinePH>
          <FamilySiteLogoLine>
            <FamilySiteLogoLinePHImg
              onClick={() =>
                handleOpenNewTab(
                  "https://www.playsongshome.com/shopinfo/introduction.html"
                )
              }
              src="https://playsongshome.playsongs.ai/logo/logo-home.png"
              alt="logo-home.png"
              width={"100%"}
            />
            <FamilySiteLogoLineArrow>
              <img
                src="https://playsongshome.playsongs.ai/icon/arrow.png"
                alt="arrow"
                width={"100%"}
                height={"100%"}
              />
            </FamilySiteLogoLineArrow>
          </FamilySiteLogoLine>
          <span>플레이송스홈</span>
        </FamilySiteLogoLinePH>
        <FamilySiteInstaImg
          onClick={() =>
            handleOpenNewTab(
              "https://www.instagram.com/playsongshome_official/"
            )
          }
          src="https://playsongshome.playsongs.ai/icon/insta.png"
          alt=""
          width={"100%"}
        />
      </FamilySiteLogoLineSection>
      <FamilySiteDescription className="text_wrap">
        플레이송스홈은 센터에서 제공하는 질 높은 수업과 그 속에서 배우고 경험한
        것들을 집에서도 반복해서 경험하고 즐길 수 있도록 고안된 홈스쿨링
        프로그램입니다.
      </FamilySiteDescription>
    </FamilySitesection>
  );
}

function Charlie() {
  return (
    <FamilySitesection>
      <FamilySiteLogoLineSection>
        <FamilySiteLogoLineC>
          <FamilySiteLogoLine>
            <FamilySiteLogoLineCImg
              onClick={() =>
                handleOpenNewTab("https://charliesbigredhouse.com/")
              }
              src="https://playsongshome.playsongs.ai/logo/logo-charlie.png"
              alt=""
              width={"100%"}
            />
            <FamilySiteLogoLineArrow>
              <img
                src="https://playsongshome.playsongs.ai/icon/arrow.png"
                alt="arrow"
                width={"100%"}
                height={"100%"}
              />
            </FamilySiteLogoLineArrow>
          </FamilySiteLogoLine>

          <span>찰리스빅레드하우스</span>
        </FamilySiteLogoLineC>
        <Default>
          <FamilySiteInstaImg
            onClick={() =>
              handleOpenNewTab(
                "https://www.instagram.com/charliesbigredhouse_in_hannam/"
              )
            }
            src="https://playsongshome.playsongs.ai/icon/insta.png"
            alt=""
            width={"100%"}
          />
          <FamilySiteInsta>
            <span>한남</span>
          </FamilySiteInsta>

          <FamilySiteInstaImg
            onClick={() =>
              handleOpenNewTab(
                "https://www.instagram.com/charlies_in_gwanggyo/"
              )
            }
            src="https://playsongshome.playsongs.ai/icon/insta.png"
            alt=""
            width={"100%"}
          />
          <FamilySiteInsta>
            <span>광교</span>
          </FamilySiteInsta>
        </Default>
      </FamilySiteLogoLineSection>
      <Mobile>
        <FamilySiteInstaLine>
          <FamilySiteInstaImg
            onClick={() =>
              handleOpenNewTab(
                "https://www.instagram.com/charliesbigredhouse_in_hannam/"
              )
            }
            src="https://playsongshome.playsongs.ai/icon/insta.png"
            alt=""
            width={"100%"}
            className="playsongs-familysite-logo-insta"
          />
          <FamilySiteInsta>
            <span>한남</span>
          </FamilySiteInsta>

          <FamilySiteInstaImg
            onClick={() =>
              handleOpenNewTab(
                "https://www.instagram.com/charlies_in_gwanggyo/"
              )
            }
            src="https://playsongshome.playsongs.ai/icon/insta.png"
            alt="insta"
            width={"100%"}
            className="playsongs-familysite-logo-insta"
          />
          <FamilySiteInsta>
            <span>광교</span>
          </FamilySiteInsta>
        </FamilySiteInstaLine>
      </Mobile>
      <FamilySiteDescription className="text_wrap">
        플레이송스와 교육철학과 비전을 공유하며 플레이송스를 졸업한 친구들이
        계속해서 수준 높은 교육을 받으며 행복한 유년기를 보낼 수 있도록
        설립되었습니다.
      </FamilySiteDescription>
    </FamilySitesection>
  );
}
function AppService() {
  return (
    <>
      <ASTitle>{StringManager.appserviceString.title}</ASTitle>
      <ASAppInfo
        title={StringManager.appserviceString.homeDecription.title}
        desc={StringManager.appserviceString.homeDecription.desc}
        img="https://playsongshome.playsongs.ai/logo/logo-app-home.png"
        alt="app-home"
      />

      <ASDownLoad
        google={
          "https://play.google.com/store/apps/details?id=com.wotnex.playsongs"
        }
        app={"https://apps.apple.com/kr/app/id1584923337"}
      />

      <ASAppInfo
        title={StringManager.appserviceString.partyDecription.title}
        desc={StringManager.appserviceString.partyDecription.desc}
        img="https://playsongshome.playsongs.ai/logo/logo-party.png"
        alt="app-party"
      />
      <ASDownLoad
        google={
          "https://play.google.com/store/apps/details?id=com.playsongs.mobilereader"
        }
        app={"https://apps.apple.com/us/app/id1148832667"}
      />
    </>
  );
}
function ASDownLoad({ google, app }: ASDownLoadProps) {
  return (
    <ASSectionDownLoadZone>
      <ASSectionDownLoadTitle>
        {StringManager.appserviceString.download}
      </ASSectionDownLoadTitle>
      <ASSectionDownLoadArrowImage
        src="https://playsongshome.playsongs.ai/icon/arrow-right-long.png"
        alt="arrow"
        width={"100%"}
      />
      <ASSectionDownLoadIconImage
        onClick={() => handleOpenNewTab(google)}
        src="https://playsongshome.playsongs.ai/icon/store-google.png"
        alt="store-google"
        height={"100%"}
      />
      <ASSectionDownLoadIconImage2
        onClick={() => handleOpenNewTab(app)}
        src="https://playsongshome.playsongs.ai/icon/store-app.png"
        alt="store-app"
        height={"100%"}
      />
    </ASSectionDownLoadZone>
  );
}
function ASAppInfo({ title, desc, img, alt }: ASAppInfoProps) {
  const screenType = useScreenType();

  if (screenType == "pc")
    return <ASDesktopAppInfo title={title} desc={desc} img={img} alt={alt} />;
  else
    return (
      <ASTabletMobileAppInfo title={title} desc={desc} img={img} alt={alt} />
    );
}
function ASTabletMobileAppInfo({ title, desc, img, alt }: ASAppInfoProps) {
  return (
    <>
      <ASSection>
        <ASSectionImage src={img} alt={alt} width={"100%"} />
        <ASSectionTitle>{title}</ASSectionTitle>
      </ASSection>
      <ASSectionDescription>{desc}</ASSectionDescription>
    </>
  );
}

function ASDesktopAppInfo({ title, desc, img, alt }: ASAppInfoProps) {
  return (
    <>
      <ASSection>
        <ASSectionTextZone>
          <ASSectionTitle>{title}</ASSectionTitle>
          <ASSectionDescription>{desc}</ASSectionDescription>
        </ASSectionTextZone>
        <ASSectionImage src={img} alt={alt} width={"100%"} />
      </ASSection>
    </>
  );
}
