import { Desktop, Mobile, Tablet } from "../Device";
import {
  BannerView,
  BodyView,
  LearningImageView,
  LearningPageInfoImgView,
  PathStringView,
  WWUDescription,
  WWUDescription2,
  WWUDescription3,
  WWUDescriptionSection,
  WWUTitleView,
} from "./common";

function WorkWithUs() {
  return (
    <>
      <Mobile>
        <MobileWorkWithUs />
      </Mobile>
      <Desktop>
        <DesktopWorkWithUs />
      </Desktop>
      <Tablet>
        <TabletWorkWithUs />
      </Tablet>
    </>
  );
}

function DesktopWorkWithUs() {
  return (
    <BodyView>
      <PathStringView className="path">Work With Us</PathStringView>
      <div className="separatorline" />
      <BannerView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/work/pc/workwithus-banner.jpg"
          alt="workwithus-img01"
          width={"100%"}
        />
      </BannerView>
      <WWUTitleView>Work with us</WWUTitleView>
      <WWUDescriptionSection>
        <WWUDescription className="text_wrap">
          At CHARLIE’S BIG RED HOUSE, we acknowledge our teachers' role in
          fostering the growth of our children and acknowledge their dedication
          through a competitive salary. Also importantly, we provide and
          encourage a work environment defined by mutual respect, a sense of
          accountability, professional development, and, of course, the joy of
          teaching. For those passionate educators who identify with our
          play-based and child-centered approach to learning and meet the
          requirements listed below are invited to submit their application
          documents.
        </WWUDescription>
        <WWUDescription2 className="text_wrap">
          · Bachelor’s degree in early childhood education, elementary education
          or child development
          {"\n"}· International or Korean teacher’s certification preferred
          {"\n"}· Classroom teaching experience
        </WWUDescription2>
        <WWUDescription2>
          Please submit a cover letter and CV to the Head of CHARLIE’S BIG RED
          HOUSE
        </WWUDescription2>
        <WWUDescription>contact_charlie@playsongs.co.kr</WWUDescription>
      </WWUDescriptionSection>
    </BodyView>
  );
}

function TabletWorkWithUs() {
  return (
    <BodyView className="text_wrap">
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/work/pc/workwithus-banner.jpg"
          alt="workwithus-ta-img01"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <WWUTitleView>Work with us</WWUTitleView>
      <WWUDescriptionSection>
        <WWUDescription className="text_wrap">
          At CHARLIE’S BIG RED HOUSE, we acknowledge our teachers' role in
          fostering the growth of our children and acknowledge their dedication
          through a competitive salary. Also importantly, we provide and
          encourage a work environment defined by mutual respect, a sense of
          accountability, professional development, and, of course, the joy of
          teaching.{"\n\n"}
          For those passionate educators who identify with our play-based and
          child-centered approach to learning and meet the requirements listed
          below are invited to submit their application documents.
        </WWUDescription>
        <WWUDescription2 className="text_wrap">
          · Bachelor’s degree in early childhood education, {"\n"} {"  "}
          elementary education or child development {"\n\n"}· International or
          Korean teacher’s certification preferred {"\n\n"}· Classroom teaching
          experience
        </WWUDescription2>
        <WWUDescription2 className="text_wrap">
          Please submit a cover letter and CV to the Head of {"\n"}CHARLIE’S BIG
          RED HOUSE
        </WWUDescription2>
        <WWUDescription>contact_charlie@playsongs.co.kr</WWUDescription>
      </WWUDescriptionSection>
    </BodyView>
  );
}

function MobileWorkWithUs() {
  return (
    <>
      <LearningImageView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/work/Mobile/work-mo.png"
          alt="workwithus-ta-img01"
          width={"100%"}
        />
      </LearningImageView>
      <BodyView className="text_wrap">
        <WWUTitleView>Work with us</WWUTitleView>
        <WWUDescriptionSection>
          <WWUDescription className="text_wrap">
            At CHARLIE’S BIG RED HOUSE, we acknowledge our teachers' role in
            fostering the growth of our children and acknowledge their
            dedication through a competitive salary. Also importantly, we
            provide and encourage a work environment defined by mutual respect,
            a sense of accountability, professional development, and, of course,
            the joy of teaching.{"\n\n"}
            For those passionate educators who identify with our play-based{" "}
            {"\n"}
            and child-centered approach to learning and meet the requirements
            listed below are invited to submit their application documents.
          </WWUDescription>
          <WWUDescription2 className="text_wrap">
            · Bachelor’s degree in early childhood education, elementary
            education or child development {"\n\n"}· International or Korean
            teacher’s certification preferred {"\n\n"}· Classroom teaching
            experience
          </WWUDescription2>
          <WWUDescription2 className="text_wrap">
            Please submit a cover letter and CV {"\n"}to the Head of CHARLIE’S
            BIG RED HOUSE
          </WWUDescription2>
          <WWUDescription3>contact_charlie@playsongs.co.kr</WWUDescription3>
        </WWUDescriptionSection>
      </BodyView>
    </>
  );
}
export default WorkWithUs;
