import { createBrowserRouter } from "react-router-dom";
import Root from "./Root";
import AboutUs from "./screens/Aboutus/AboutUs";
import MissionandValue from "./screens/Aboutus/MissionandValue";
import Admission from "./screens/Admission";
import ContactUs from "./screens/ContactUs";
import Home from "./screens/Home";
import Elemantary from "./screens/Learning/Elemantary";
import Learning from "./screens/Learning/Learning";
import PreSchool from "./screens/Learning/PreSchool";
import PrimarySchoolGwanggyo from "./screens/Learning/PrimarySchoolGwanggyo";
import PrimarySchoolHannam2 from "./screens/Learning/PrimarySchoolHannam2";
import NotFound from "./screens/NotFound";
import FamilySite from "./screens/FamilySite";
import WorkWithUs from "./screens/WorkWithUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <NotFound />,
      },
      {
        path: "aboutus",
        element: <AboutUs />,
      },
      {
        path: "AboutUs/MissionandValue",
        element: <MissionandValue />,
      },

      {
        path: "Learning",
        element: <Learning />,
      },
      {
        path: "Learning/PreSchool",
        element: <PreSchool />,
      },
      {
        path: "Learning/PrimarySchoolHannam2",
        element: <PrimarySchoolHannam2 />,
      },
      {
        path: "Learning/PrimarySchoolGwanggyo",
        element: <PrimarySchoolGwanggyo />,
      },
      {
        path: "Learning/Elemantary",
        element: <Elemantary />,
      },
      {
        path: "Admission",
        element: <Admission />,
      },
      {
        path: "ContactUs",
        element: <ContactUs />,
      },
      {
        path: "WorkWithUs",
        element: <WorkWithUs />,
      },
      {
        path: "FamilySite",
        element: <FamilySite />,
      },
    ],
    errorElement: <NotFound />,
  },
]);
export default router;
