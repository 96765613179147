import styled from "styled-components";

export const SeparatorView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.8333vw;
    border: solid 0.0521vw #222222;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 79.1667vw;
    margin: 2.6042vw 0.013vw 0 0.1953vw;
    border: solid 0.1302vw #222222;
  }

  @media (max-width: 767px) {
    width: 85.5814vw;
    margin: 3.2558vw 0 0;
    border: solid 0.2326vw #222222;
  }
  height: 0;
`;

export const MainSeparatorView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 15.1vw;
    margin-right: 9.375vw;
    border: solid 0.0521vw #707070;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 99.9349vw;
    margin-top: 12.8906vw;
    border: solid 0.0651vw #707070;
  }

  @media (max-width: 767px) {
    margin-top: 12.907vw;
    width: 100%;
    border: solid 0.2326vw #707070;
  }
  height: 0;
`;

export const MainView = styled.div`
  @media (min-width: 1024px) {
    margin-left: 28.9063vw;
    margin-right: 9.375vw;
    margin-top: 1.3438vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.6042vw;
    padding: 0.5208vw 10.3385vw 1.8229vw 10.2865vw;
  }
  @media (max-width: 767px) {
    margin-top: 3.1395vw;
    padding: 2.4419vw 3.6047vw 5.3488vw 3.7209vw;
  }

  //background-color: #c8bdb6;
`;

export const InfoView = styled.div``;

export const CopyrightLineView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.8333vw;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    margin-top: 2.6042vw;
  }
  @media (max-width: 767px) {
  }
`;
export const LogoView = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: center; //end; //center;
  align-items: end;
  @media (min-width: 1024px) {
    width: 22.3958vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 40.8854vw;
  }
  @media (max-width: 767px) {
    width: 72.907vw;
    //align-items: end;
  }
`;

export const OtherLogoView = styled(LogoView)`
  @media (min-width: 1024px) {
    width: 16.0677vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 27.2005vw;
  }
  @media (max-width: 767px) {
    margin-top: 3.2558vw;
    width: 48.6047vw;
  }
`;

export const OtherLogo = styled.div`
  @media (min-width: 1024px) {
    width: 3.1771vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 5.2083vw;
  }
  @media (max-width: 767px) {
    width: 9.3023vw;
  }
`;

export const OtherLogoPointer = styled(OtherLogo)`
  &:hover {
    cursor: pointer;
  }
`;
export const Other2Logo = styled.div`
  @media (min-width: 1024px) {
    width: 4.6667vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.1979vw;
  }
  @media (max-width: 767px) {
    width: 15.814vw;
  }
`;
export const LogoBicycleView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 0.7292vw;
    width: 6.0052vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 10.8854vw;
  }
  @media (max-width: 767px) {
    width: 19.4419vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const LogoLineView = styled.div`
  @media (min-width: 1024px) {
    margin-top: 1.1719vw;
    width: 0.0729vw;
    height: 1.7344vw;
    background-color: #321d13;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 0;
    height: 3.4896vw;
    border: solid 0.1042vw #222222;
  }
  @media (max-width: 767px) {
    width: 0.186vw;
    height: 6.2326vw;
    background-color: #222222;
  }
`;
export const LogoPlaysongsView = styled.div`
  @media (min-width: 1024px) {
    width: 3.5625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.4583vw;
  }
  @media (max-width: 767px) {
    width: 11.5349vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const LogoHomeView = styled.div`
  @media (min-width: 1024px) {
    width: 3.5521vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.4323vw;
  }
  @media (max-width: 767px) {
    width: 11.4884vw;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const LogocharliesView = styled.div`
  @media (min-width: 1024px) {
    width: 3.3021vw;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 6.794vw;
  }
  @media (max-width: 767px) {
    width: 11.1628vw;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const LogoIEYCView = styled.div`
  @media (min-width: 1024px) {
    width: 5.5208vw;
    height: 5.5208vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 5.5208vw;
    height: 5.5208vw;
  }
  @media (max-width: 767px) {
    width: 9.3023vw;
    height: 9.3023vw;
  }
`;

export const TextView = styled.div`
  line-height: 1.86;
  @media (min-width: 1024px) {
    margin-top: 0.4vw;
    font-size: 0.7292vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.0313vw;
    font-size: 2.0833vw;
    line-height: 1.63;
  }
  @media (max-width: 767px) {
    margin-top: 4.4vw;
    font-size: 3.2558vw;
    letter-spacing: -0.0651vw;
  }

  font-family: Pretendard;
  text-align: left;
  color: #222222;
`;

export const TitleTextView = styled.span`
  @media (min-width: 1024px) {
    font-size: 1.0417vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.0833vw;
    line-height: 1.63;
  }
  @media (max-width: 767px) {
    font-size: 3.7209vw;
    letter-spacing: -0.0744vw;
  }

  font-family: Pretendard;
  color: #222222;
`;

export const CopyrightTextView = styled(TextView)`
  @media (min-width: 1024px) {
    font-size: 0.8333vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 2.0313vw;
    font-size: 1.8229vw;
    line-height: 2;
  }
`;
