import { Desktop, Mobile, Tablet } from "../../Device";
import {
  LearningPageInfoTextView2,
  LearningPageInfoImgView,
  LearningPageInfoText2View,
  LearningPageInfoView,
  LearningPageInfoTextView,
  LearningPageInfoDescriptionView,
  LearningPageInfoLinkView,
  LearningPageInfoLinkTextView,
  LearningPageInfoLinkSectionView,
  LearningPageInfoLinkText2View,
  BodyView,
  PathStringView,
  LearningPageTitleView,
  LearningImageView,
  BannerView,
  handleOpenNewTab,
} from "../common";

function PrimarySchoolHannam2() {
  return (
    <>
      <Desktop>
        <DesktopPrimarySchoolHannam2 />
      </Desktop>
      <Tablet>
        <TabletPrimarySchoolHannam2 />
      </Tablet>
      <Mobile>
        <MobilePrimarySchoolHannam2 />
      </Mobile>
    </>
  );
}
function MobilePrimarySchoolHannam2() {
  return (
    <>
      <LearningImageView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/mobile/banner-pri-hannam2-01%403x.png"
          alt="banner-elemantary"
          width={"100%"}
        />
      </LearningImageView>
      <BodyView>
        <LearningPageTitleView className="text_wrap">
          HanNam Pre-K and Kinder Program {"\n"}(4~6 age)
        </LearningPageTitleView>

        <LearningPageInfoTextView className="text_wrap">
          CHARLIE’S BIG RED HOUSE provides a dual language curriculum (Korean
          and English) for children aged 4 to 6.
        </LearningPageInfoTextView>
        <LearningPageInfoText2View className="text_wrap">
          4세부터 6세까지 유아에게 한국어와 영어를 동시에 습득하는
          환경(한국인/원어민 두 담임제)과 커리큘럼을 지원합니다.
        </LearningPageInfoText2View>

        <LearningPageInfoImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/tablet/2410/tablet-hannam-2-cam-2.jpg"
            alt="img02"
            width={"100%"}
          />
        </LearningPageInfoImgView>
        <LearningPageInfoTextView2 className="text_wrap">
          We foster a play-based learning environment that encourages children's
          natural curiosity and eagerness to learn. We follow a cross-curricular
          approach with various theme topics based on the individual development
          stage of children; students will internalize learning and see that it
          is everywhere and in everything we do.
        </LearningPageInfoTextView2>
        <LearningPageInfoText2View className="text_wrap">
          유아의 개별적인 발달단계를 중요시 여기며 교재 중심의 활동을 지양하고,
          하나의 주제를 중심으로 유아가 주도적으로 참여할 수 있는 놀이중심의
          여러 영역의 교육활동(언어, 수·과학, 미술, 음악, 체육)이 통합적으로
          이루어집니다.
        </LearningPageInfoText2View>
        <LearningPageInfoImgView>
          <img
            src="https://playsongshome.playsongs.ai/charlie/learning/mobile/hannam2-pri-img02%403x.png"
            alt="img02"
            width={"100%"}
          />
        </LearningPageInfoImgView>
        <LearningPageInfoTextView2 className="text_wrap">
          CHARLIE’S BIG RED HOUSE specialized literary program aims at the "Joy
          of reading." The children will develop reading skills by expanding
          their background knowledge of picture books, reading original English
          picture books and translated versions in Korean, and fun activities
          after reading.
        </LearningPageInfoTextView2>
        <LearningPageInfoText2View className="text_wrap">
          찰리스빅레드하우스만의 특화된 문학프로그램은 ‘평생 책 읽는 즐거움을
          아는 독서가’를 목표로 배경지식을 넓히기 위한 사전활동, 양질의 그림책을
          원서와 번역서로 읽기, 책과 관련한 대한 사후활동이 이루어집니다.
        </LearningPageInfoText2View>

        <LearningPageInfoLinkTextView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")
          }
        >
          {" "}
          → Request information
        </LearningPageInfoLinkTextView>
        <LearningPageInfoLinkTextView
          onClick={() =>
            handleOpenNewTab("https://forms.gle/sdWYFkXZ79Awb5FY6")
          }
        >
          {" "}
          → Visit Us
        </LearningPageInfoLinkTextView>
      </BodyView>
    </>
  );
}
function TabletPrimarySchoolHannam2() {
  return (
    <BodyView>
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/ta-hannam-2-main%403x.png"
          alt="banner-elemantary"
          width={"100%"}
        />
      </LearningPageInfoImgView>

      <LearningPageTitleView>
        HanNam Pre-K and Kinder Program (4~6 age)
      </LearningPageTitleView>

      <LearningPageInfoTextView className="text_wrap">
        CHARLIE’S BIG RED HOUSE provides a dual language curriculum (Korean and
        English) for children aged 4 to 6.
      </LearningPageInfoTextView>
      <LearningPageInfoText2View className="text_wrap">
        4세부터 6세까지 유아에게 한국어와 영어를 동시에 습득하는
        환경(한국인/원어민{"\n"}두 담임제)과 커리큘럼을 지원합니다.
      </LearningPageInfoText2View>

      <LearningPageInfoImgView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/2410/tablet-hannam-2-cam-2.jpg"
          alt="img02"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <LearningPageInfoTextView2 className="text_wrap">
        We foster a play-based learning environment that encourages children's
        natural curiosity and eagerness to learn. We follow a cross-curricular
        approach with various theme topics based on the individual development
        stage of children; students will internalize learning and see that it is
        everywhere and in everything we do.
      </LearningPageInfoTextView2>
      <LearningPageInfoText2View className="text_wrap">
        유아의 개별적인 발달단계를 중요시 여기며 교재 중심의 활동을 지양하고,
        하나의 주제를 중심으로 유아가 주도적으로 참여할 수 있는 놀이중심의 여러
        영역의 교육활동(언어, 수·과학, 미술, 음악, 체육)이 통합적으로
        이루어집니다.
      </LearningPageInfoText2View>
      <LearningPageInfoImgView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/hannam2-pri-img02%403x.png"
          alt="img02"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <LearningPageInfoTextView2 className="text_wrap">
        CHARLIE’S BIG RED HOUSE specialized literary program aims at the "Joy of
        reading." The children will develop reading skills by expanding their
        background knowledge of picture books, reading original English picture
        books and translated versions in Korean, and fun activities after
        reading.
      </LearningPageInfoTextView2>
      <LearningPageInfoText2View className="text_wrap">
        찰리스빅레드하우스만의 특화된 문학프로그램은 ‘평생 책 읽는 즐거움을 아는
        독서가’를 목표로 배경지식을 넓히기 위한 사전활동, 양질의 그림책을 원서와
        번역서로 읽기, 책과 관련한 대한 사후활동이 이루어집니다.
      </LearningPageInfoText2View>

      <LearningPageInfoLinkTextView
        onClick={() => handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")}
      >
        {" "}
        → Request information
      </LearningPageInfoLinkTextView>
      <LearningPageInfoLinkTextView
        onClick={() => handleOpenNewTab("https://forms.gle/sdWYFkXZ79Awb5FY6")}
      >
        {" "}
        → Visit Us
      </LearningPageInfoLinkTextView>
    </BodyView>
  );
}
function DesktopPrimarySchoolHannam2() {
  return (
    <BodyView>
      <PathStringView className="path">
        Learning {">"} HanNam Pre-K and Kinder Program (4~6 age)
      </PathStringView>
      <div className="separatorline" />
      <BannerView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/pc/0321/banner-hannam-2-main.png"
          alt="banner-elemantary"
          width={"100%"}
        />
      </BannerView>

      <LearningPageTitleView>
        HanNam Pre-K and Kinder Program (4~6 age)
      </LearningPageTitleView>

      <LearningPageInfoView>
        <LearningPageInfoDescriptionView>
          <LearningPageInfoTextView className="text_wrap">
            CHARLIE’S BIG RED HOUSE provides a dual language curriculum (Korean
            and English) for children aged 4 to 6.
          </LearningPageInfoTextView>
          <LearningPageInfoText2View className="text_wrap">
            4세부터 6세까지 유아에게 한국어와 영어를 동시에 습득하는
            환경(한국인/원어민{"\n"}두 담임제)과 커리큘럼을 지원합니다.
          </LearningPageInfoText2View>

          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/pc/2410/sub-pc-hannam-2-cam-2.jpg"
              alt="img02"
              width={"100%"}
            />
          </LearningPageInfoImgView>
          <LearningPageInfoTextView2 className="text_wrap">
            We foster a play-based learning environment that encourages
            children's natural curiosity and eagerness to learn. We follow a
            cross-curricular approach with various theme topics based on the
            individual development stage of children; students will internalize
            learning and see that it is everywhere and in everything we do.
          </LearningPageInfoTextView2>
          <LearningPageInfoText2View className="text_wrap">
            유아의 개별적인 발달단계를 중요시 여기며 교재 중심의 활동을
            지양하고, 하나의 주제를 중심으로 유아가 주도적으로 참여할 수 있는
            놀이중심의 여러 영역의 교육활동(언어, 수·과학, 미술, 음악, 체육)이
            통합적으로 이루어집니다.
          </LearningPageInfoText2View>
          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/hannam2-pri-img02%403x.png"
              alt="img02"
              width={"100%"}
            />
          </LearningPageInfoImgView>
          <LearningPageInfoTextView2 className="text_wrap">
            CHARLIE’S BIG RED HOUSE specialized literary program aims at the
            "Joy of reading." The children will develop reading skills by
            expanding their background knowledge of picture books, reading
            original English picture books and translated versions in Korean,
            and fun activities after reading.
          </LearningPageInfoTextView2>
          <LearningPageInfoText2View className="text_wrap">
            찰리스빅레드하우스만의 특화된 문학프로그램은 ‘평생 책 읽는 즐거움을
            아는 독서가’를 목표로 배경지식을 넓히기 위한 사전활동, 양질의
            그림책을 원서와 번역서로 읽기, 책과 관련한 대한 사후활동이
            이루어집니다.
          </LearningPageInfoText2View>
        </LearningPageInfoDescriptionView>
        <LearningPageInfoLinkSectionView>
          <LearningPageInfoLinkView>
            <LearningPageInfoLinkTextView
              onClick={() =>
                handleOpenNewTab("https://forms.gle/LZ74fua6xHLisY2FA")
              }
            >
              {" "}
              → Request information
            </LearningPageInfoLinkTextView>
          </LearningPageInfoLinkView>
        </LearningPageInfoLinkSectionView>
      </LearningPageInfoView>
    </BodyView>
  );
}
export default PrimarySchoolHannam2;
