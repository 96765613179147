import { Desktop, Mobile, Tablet } from "../../Device";
import {
  LearningPageInfoImgView,
  LearningPageInfoTextView2,
  LearningPageTitleView,
  LearningPageInfoTextView,
  LearningPageInfoView,
  LearningPageInfoDescriptionView,
  BannerView,
  BodyView,
  PathStringView,
  handleOpenNewTab,
  LearningPageInfoLinkSectionView,
  LearningPageInfoLinkView,
  LearningPageInfoLinkTextView,
  LearningPageInfoLinkText2View,
} from "../common";

//////

function Elemantary() {
  return (
    <>
      <Desktop>
        <DesktopElemantary />
      </Desktop>
      <Tablet>
        <TabletElemantary />
      </Tablet>
      <Mobile>
        <MobileElemantary />
      </Mobile>
    </>
  );
}
function MobileElemantary() {
  return (
    <>
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/mobile/241025/gw-ele-main.jpg"
          alt="banner-elemantary"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <BodyView className="text_wrap">
        <ElemantaryInfo />
      </BodyView>
    </>
  );
}
function TabletElemantary() {
  return (
    <BodyView className="text_wrap">
      <LearningPageInfoImgView style={{ marginTop: "0" }}>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/241025/gw-ele-main.jpg"
          alt="banner-elemantary"
          width={"100%"}
        />
      </LearningPageInfoImgView>

      <ElemantaryInfo />
    </BodyView>
  );
}
function ElemantaryInfo() {
  return (
    <>
      <LearningPageTitleView>
        Gwanggyo Elementary Program (7~9 age)
      </LearningPageTitleView>

      <LearningPageInfoTextView className="text_wrap">
        CHARLIE’S BIG RED HOUSE Elementary Program(Grade 1,2,3) is designed to
        provide in-depth English learning for each area within the Immersion
        Learning and to further develop the English skills built up through the
        English Kinder Program program.
      </LearningPageInfoTextView>

      <LearningPageInfoImgView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/241025/gw-ele-2.jpg"
          alt="img03"
          width={"100%"}
        />
      </LearningPageInfoImgView>

      <LearningPageInfoTextView2>
        By accumulating various background knowledge and learning difficult
        vocabulary and expressions through International subjects, language
        communication skills to speak accurate and fluent English are developed,
        and through various evaluations, children's learning tendencies and
        growth are checked in various ways to suggest the right learning
        direction.
      </LearningPageInfoTextView2>

      <LearningPageInfoImgView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/tablet/241025/gw-ele-3.jpg"
          alt="img04"
          width={"100%"}
        />
      </LearningPageInfoImgView>
      <LearningPageInfoTextView2>
        CHARLIE’S BIG RED HOUSE helps children grow to become global leaders
        equipped with the core competencies needed in our future society through
        expanding knowledge through various topics and increasing their thinking
        skills by encountering common sense in a wide range of fields, and
        critical thinking.
      </LearningPageInfoTextView2>
      <LearningPageInfoLinkTextView
        onClick={() =>
          handleOpenNewTab(
            "https://docs.google.com/forms/d/1c-yoy6nduAyF-g2-kWjuR8NDgjfRguxMc2UHiFSApt0/viewform?edit_requested=true"
          )
        }
      >
        {" "}
        → Request information
      </LearningPageInfoLinkTextView>
      <LearningPageInfoLinkTextView
        onClick={() => handleOpenNewTab("https://forms.gle/8uPcG5J1WFsFEiDF6")}
      >
        {" "}
        → Visit Us
      </LearningPageInfoLinkTextView>
    </>
  );
}
function DesktopElemantary() {
  return (
    <BodyView className="text_wrap">
      <PathStringView className="path">
        Learning {">"} Gwanggyo Elementary Program (7~9 age)
      </PathStringView>
      <div className="separatorline" />
      <BannerView>
        <img
          src="https://playsongshome.playsongs.ai/charlie/learning/pc/241025/gw-ele-main.jpg"
          alt="banner-elemantary"
          width={"100%"}
        />
      </BannerView>

      <LearningPageTitleView>
        Gwanggyo Elementary Program (7~9 age)
      </LearningPageTitleView>

      <LearningPageInfoView>
        <LearningPageInfoDescriptionView>
          <LearningPageInfoTextView className="text_wrap">
            CHARLIE’S BIG RED HOUSE Elementary Program(Grade 1,2,3) is designed
            to provide in-depth English learning for each area within the
            Immersion Learning and to further develop the English skills built
            up through the English Kinder Program program.
          </LearningPageInfoTextView>

          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/pc/241025/gw-ele-2.jpg"
              alt="img03"
              width={"100%"}
            />
          </LearningPageInfoImgView>

          <LearningPageInfoTextView2>
            By accumulating various background knowledge and learning difficult
            vocabulary and expressions through International subjects, language
            communication skills to speak accurate and fluent English are
            developed, and through various evaluations, children's learning
            tendencies and growth are checked in various ways to suggest the
            right learning direction.
          </LearningPageInfoTextView2>

          <LearningPageInfoImgView>
            <img
              src="https://playsongshome.playsongs.ai/charlie/learning/pc/241025/gw-ele-3.jpg"
              alt="img04"
              width={"100%"}
            />
          </LearningPageInfoImgView>
          <LearningPageInfoTextView2>
            CHARLIE’S BIG RED HOUSE helps children grow to become global leaders
            equipped with the core competencies needed in our future society
            through expanding knowledge through various topics and increasing
            their thinking skills by encountering common sense in a wide range
            of fields, and critical thinking.
          </LearningPageInfoTextView2>
        </LearningPageInfoDescriptionView>
        <LearningPageInfoLinkSectionView>
          <LearningPageInfoLinkView>
            <LearningPageInfoLinkTextView
              onClick={() =>
                handleOpenNewTab(
                  "https://docs.google.com/forms/d/1c-yoy6nduAyF-g2-kWjuR8NDgjfRguxMc2UHiFSApt0/viewform?edit_requested=true"
                )
              }
            >
              {" "}
              → Request information
            </LearningPageInfoLinkTextView>
            <LearningPageInfoLinkText2View
              onClick={() =>
                handleOpenNewTab("https://forms.gle/8uPcG5J1WFsFEiDF6")
              }
            >
              {" "}
              → Visit Us
            </LearningPageInfoLinkText2View>
          </LearningPageInfoLinkView>
        </LearningPageInfoLinkSectionView>
      </LearningPageInfoView>
    </BodyView>
  );
}

export default Elemantary;
