import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Componets/Footer";
import Sidebar from "./Componets/Side/Sidebar";

import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}
//<Header />
function Root() {
  return (
    <CookiesProvider>
      <ScrollToTop />
      <Sidebar />
      <Outlet />
      <Footer />
    </CookiesProvider>
  );
}

export default Root;
