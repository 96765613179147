import styled from "styled-components";

export const TabMenuView = styled.ul`
  color: #222;
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  align-items: center;
  list-style: none;

  margin-top: 1.1458vw;
  padding: 0;

  //background-color: pink;
  .submenu {
    // 기본 Tabmenu 에 대한 CSS를 구현
    display: flex;
    align-items: center;
    font-weight: bold;
    text-align: center;

    @media (min-width: 1024px) {
      width: 11.4583vw;
      height: 4.6875vw;
      padding: 0.5208vw;
      font-size: 1.4063vw;
      line-height: 1.26;
      border-bottom: solid 0.1042vw #222;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 16.1458vw;
      height: 15.3646vw;
      font-size: 3.125vw;
      line-height: 1.25;
      border-bottom: solid 0.2604vw #222;
    }
    @media (max-width: 767px) {
      width: 21.3953vw;
      height: 20.6977vw;
      font-size: 4.186vw;
      line-height: 1.2;
    }
    // transition: 0.5s;
  }

  .focused {
    //선택된 Tabmenu 에만 적용되는 CSS를 구현
    background-color: rgb(255, 255, 255);
    color: #f70000;
    @media (min-width: 1024px) {
      border-bottom: solid 0.1042vw #f70000;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      text-align: center;
      border-bottom: solid 0.2604vw #f70000;
    }
    @media (max-width: 767px) {
      text-align: center;
      border-bottom: solid 0.4651vw #f70000;
    }
  }

  &:hover {
    cursor: pointer;
  }
  & div.desc {
    text-align: center;
  }
`;

export const Desc = styled.div`
  text-align: center;
`;
