import { StringManager } from "../assets/StringManager";

import { handleOpenNewTab } from "../screens/common";
import { useScreenType } from "./Devicets";
import {
  CopyrightLineView,
  InfoView,
  LogoBicycleView,
  LogocharliesView,
  LogoHomeView,
  LogoLineView,
  LogoPlaysongsView,
  LogoView,
  MainView,
  Other2Logo,
  OtherLogo,
  OtherLogoView,
  SeparatorView,
  TextView,
  TitleTextView,
  MainSeparatorView,
  CopyrightTextView,
  OtherLogoPointer,
} from "./FooterStyle";

export default function Footer() {
  return (
    <footer>
      <DesktopFooter />
    </footer>
  );
}

function DesktopFooter() {
  const screenType = useScreenType();
  return (
    <>
      <MainSeparatorView />
      <MainView>
        <InfoView>
          <LogoView>
            <LogoBicycleView
              onClick={() =>
                handleOpenNewTab("http://www.playsongs.co.kr/bicycle/")
              }
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/bicycle%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoBicycleView>
            <LogoLineView />
            <LogoPlaysongsView
              onClick={() => handleOpenNewTab("http://www.playsongs.co.kr")}
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/ps%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoPlaysongsView>
            <LogoHomeView
              onClick={() => handleOpenNewTab("https://playsongshome.com/")}
            >
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/home%403x.png"
                alt=""
                width={"100%"}
              />
            </LogoHomeView>
            <LogocharliesView onClick={() => handleOpenNewTab("/")}>
              <img
                src="https://playsongshome.playsongs.ai/charlie/logo/charlies%403x.png"
                alt=""
                width={"100%"}
              />
            </LogocharliesView>
          </LogoView>

          <TextView className="text_wrap">
            <TitleTextView>{StringManager.footerString.title}</TitleTextView>
            {StringManager.footerString.description[screenType]}
          </TextView>
        </InfoView>
        <SeparatorView />
        <CopyrightLineView>
          <CopyrightTextView>
            {StringManager.footerString.copy}
          </CopyrightTextView>

          <OtherLogoView>
            <OtherLogoPointer>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/ieyc-square-logo-with-text%403x.png"
                alt=""
                width={"100%"}
                onClick={() =>
                  handleOpenNewTab(
                    "https://playsongshome.playsongs.ai/charlie/IEYC.pdf"
                  )
                }
              />
            </OtherLogoPointer>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/mom-logo%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <OtherLogo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/bcli%403x.png"
                alt=""
                width={"100%"}
              />
            </OtherLogo>
            <Other2Logo>
              <img
                src="https://playsongshome.playsongs.ai/logo/footer/choice-logo%403x.png"
                alt=""
                width={"100%"}
              />
            </Other2Logo>
          </OtherLogoView>
        </CopyrightLineView>
      </MainView>
    </>
  );
}
